.background {
  position: fixed;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  padding: 40px 20px 20px;
}

.click-capturer {
  display: inline-block;
  position: relative;
  height: fit-content;
  // margin: 15px;
  // margin-top: 20vh;

  img,
  video {
    max-width: 90vw;
  }
}

.close {
  position: absolute;
  top: -35px;
  right: -5px;
  color: #fff;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
}

.close:focus,
.close:hover {
  cursor: pointer;
  outline: none;
  text-shadow: $hover-text-shadow;
}

.close:after {
  content: "×";
}

@media screen and (min-width: 768px) {
  .close {
    right: -35px;
  }
}
