.about {
  display: flex;
  margin: 2rem 0.5rem;

  &-container {
    display: inline-flex;
    vertical-align: top;
    flex-wrap: wrap;
  }

  img {
    max-height: 500px;
    border-radius: 40px;
    // width: auto;
    object-fit: cover;
  }
  &-text {
    flex: 1;
    text-align: left;
    padding: 0 1rem;
  }
  .landing-links {
    @extend .flex-row;
    display: none;
    margin-bottom: 1em;
    z-index: 1;
    position: relative;
    a {
      margin: 1em;
      padding: 1em;
    }
  }
}
.cert {
  height: 350px;
  width: auto;
  margin: 1rem 2rem;
  cursor: pointer;
}

.job {
  @extend .blur-bg;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  h5 {
    margin: 1.25rem 0;
  }
  &-wrapper {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .about {
    margin: 2rem 1rem 1rem 1rem;
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 4rem;
    }
    &-container {
      display: flex;
      flex-direction: row-reverse;

      .about-text {
        // width: 65%;
        max-width: calc(1440px - 400px);
      }
      // img {
      //   object-fit: cover;
      //   width: 30%;
      // }
    }
    .blur-bg {
      flex-direction: row;
    }
    .landing-links {
      display: flex;
      justify-content: flex-start;
    }
  }
}
