/* PROJECTS */
.projects {
  @extend .flex;
  height: 225vh;
  width: 90%;
  margin: auto;
  &-list {
    width: 100%;
    text-align: center;
    overflow-y: scroll;
    &-item {
      @extend .flex;
      flex-wrap: wrap;
      padding: 1rem;
      margin: 1rem;
      div {
        text-align: left;
      }
      img {
        height: 250px;
        width: 250px;
        object-fit: contain;
      }
    }
  }
}

@media (min-width: 768px) {
  .projects-list-item {
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    div {
      width: 100%;
    }
    h5 {
      text-align: left;
      padding: 0em;
      margin: 0em;
    }
    img {
      padding: 1em;
      margin-right: 2em;
    }
  }
}
