//make variable for height
$postHeight: 350px;

.post {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: $postHeight;
  min-width: 300px;
  margin: 0.5em;
  padding: 0 1em;

  h3 {
    font-size: large;
  }

  &-slug,
  &-link {
    text-decoration: none;
  }

  &-slug {
    text-align: left;
    font-size: small;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }
  }

  &-details {
    margin-top: 0rem;
    min-height: calc(100vh - (1rem * 2));
    padding: 1em;

    &-text {
      padding: 0 1rem;
    }

    h1 {
      font-size: x-large;
    }
    h2 {
      font-size: large;
    }
  }
}

.other-posts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: $postHeight;
  min-width: 300px;
  margin: 0.5em;
  padding: 0 1em;

  & > div {
    margin: 0.25rem 0;
  }

  h3 {
    padding: 1rem;
    font-size: large;
  }
}

.posts-video {
  &-item {
    padding: 1em;
    margin: 1em;
    width: 550px;
    height: 330px;
    iframe {
      border-radius: 10px;
    }
  }
}

@media (min-width: 768px) {
  .post-details {
    margin-top: 2rem;
    &-text {
      padding: 0 5rem;
    }
  }
}
