@font-face {
  font-display: swap;
  font-family: "Lato", sans-serif;
  src: local("Lato"), url(../fonts/Lato/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "LatoBlack", sans-serif;
  src: local("LatoBlack"), url(../fonts/Lato/Lato-Black.ttf) format("truetype");
}

@font-face {
  font-family: DMSans-Regular;
  src: url("../fonts/DM_Sans/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Didot";
  src: url("../fonts/didot/Didot-Italic.eot");
  src: local("Didot Italic"), local("Didot-Italic"),
    url("../fonts/didot/Didot-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/didot/Didot-Italic.woff2") format("woff2"),
    url("../fonts/didot/Didot-Italic.woff") format("woff"),
    url("../fonts/didot/Didot-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  * {
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    font-family: "DMSans-Regular"; //"Lato", sans-serif;;
  }
}

html {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

html,
body {
  // font-family: "DMSans-Regular"; //"Lato", sans-serif;;
  scroll-behavior: smooth;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  color: white;
}

body {
  @extend .darktheme;
  display: flex;
  position: relative;
  margin: auto;
  box-sizing: content-box;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  //margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
}

main {
  max-width: 1440px;
  margin: auto;
  overflow-x: hidden;
}

div#root::after {
  @extend .grainBg;
  position: absolute;
  top: 0;
  z-index: -1;
  content: "";
}

img,
video {
  z-index: 1;
}
#root {
  width: 100%;
}

a {
  color: white;
  font-family: "Lato", sans-serif;
  text-decoration: underline;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
p {
  color: white;
  font-family: "Lato", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  color: white;
  font-family: "Didot";
  font-weight: normal;
  letter-spacing: 2px;
  font-size: xx-large;
  // font-weight: 900;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

button {
  &:focus {
    outline: transparent;
  }
}

header {
  margin-bottom: 5.5em;
}

img {
  border-radius: 10px;
  max-height: 80vh;
}

svg {
  max-width: 250px;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
  border-radius: 15px;
}

.logo {
  display: none;
}
.main-title {
  text-align: center;
  margin-top: 2rem;

  margin: auto;
}

.grainBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/noise.svg");
  opacity: 0.075;
}

.daytheme {
  background: linear-gradient(
    0deg,
    #789ab5 20%,
    #4383b9 40%,
    #0160bb 60%,
    #01449f 80%
  );
}

.darktheme {
  background: linear-gradient(
    0deg,
    #22616b 1%,
    #103d4b 20%,
    #092330 40%,
    #050f1b 60%,
    #030712 80%
  );
}

.synthpoptheme {
  background: linear-gradient(
    0deg,
    #a816a1 1%,
    #591373 20%,
    #844fd8 40%,
    #4b5ad0 60%,
    #0c38cc 70%,
    #030712 91%
  );
}

.defaulttheme {
  background: linear-gradient(
    180deg,
    #111824 1%,
    rgb(15, 65, 99) 20%,
    rgb(167, 155, 140) 40%,
    rgb(248, 163, 98) 60%,
    rgb(204, 99, 46) 75%,
    #000000 95%
  );
}

/* responsive */
/* Medium (md) */
@media (min-width: 768px) {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-style: normal;
  }
  .logo {
    display: flex;
    justify-content: center;

    img {
      width: 500px;
      height: auto;
    }
  }
}
