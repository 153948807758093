.hscroll {
  display: flex;
  flex-direction: row;
  z-index: 2;
  overflow-x: scroll;
  margin-bottom: 5rem;
  padding: 0 2rem;

  &.show-scroll {
    &::-webkit-scrollbar {
      display: block;
      width: 1px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background: #27c0fd50;
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  &__container {
    margin: auto;
    position: relative;
  }
  &__arrow {
    @extend .blur-bg;
    cursor: pointer;
    background: transparent;
    border: none;
    backdrop-filter: blur(10px) invert(20%);
    -webkit-backdrop-filter: blur(10px) invert(20%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    height: 50%;
    width: 4rem;
    position: absolute;
    top: 25%;
    right: 0.5rem;
    z-index: 2;
  }
}
