.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row {
  @extend .flex;
  flex-direction: row;
}

.blur-bg {
  overflow: hidden;
  border-radius: 40px;
  backdrop-filter: blur(1px) invert(3%);
  -webkit-backdrop-filter: blur(1px) invert(5%);
  -webkit-box-shadow: 5px 5px 7px 3px rgba(0, 0, 0, 0.04); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 5px 5px 7px 3px rgba(0, 0, 0, 0.04); /* Firefox 3.5 - 3.6 */
  box-shadow: 5px 5px 7px 3px rgba(0, 0, 0, 0.04);
}

a:hover {
  text-shadow: $hover-text-shadow;
  text-decoration: none;
}
